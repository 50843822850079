

<template>
  <div>
    <Pane />

    <a-card class="container">
      <a-table bordered :data-source="list" :loading="loading" :pagination="false" rowKey="id">
        <a-table-column key="businessKey" title="businessKey" data-index="businessKey"></a-table-column>
        <a-table-column key="procInstId" title="procInstId" data-index="procInstId"></a-table-column>
        <a-table-column key="procName" title="procName" data-index="procName"></a-table-column>

        <a-table-column key="taskDefKey" title="taskDefKey" data-index="taskDefKey"></a-table-column>
        <a-table-column key="taskId" title="taskId" data-index="taskId"></a-table-column>
        <a-table-column key="taskName" title="taskName" data-index="taskName"></a-table-column>
        <a-table-column key="taskType" title="taskType" data-index="taskType"></a-table-column>

        <!-- <DataDictFinder dictType="logRank" :dictValue="text.rank" /> -->

        <a-table-column key="status" title="状态" data-index="status"></a-table-column>
        <a-table-column key="createAt" title="创建时间" data-index="createAt" align="center"></a-table-column>

        <a-table-column key="control" title="操作" align="center" width="160px">
          <template slot-scope="text">
            <a-space>
              <a href="#" @click.prevent="seeDetail(text)">详情</a>
              <a href="#" @click.prevent="change(text)" class="danger">触发业务回调</a>
            </a-space>
          </template>
        </a-table-column>
      </a-table>

      <a-modal title="详情" :visible="!!detail.id" @cancel="detail = {}" :footer="null">
        <div>{{detail.extra}}</div>
      </a-modal>
    </a-card>
  </div>
</template>

<script>
import request from "@/api/request";

function fetchList() {
  return request({
    url: "/activiti-service/servicetask/task/reviewFail/list"
  });
}

function control(data) {
  return request({
    url: "/activiti-service/servicetask/task/serviceCallback",
    method: "post",
    data
  });
}

export default {
  data() {
    return {
      loading: false,
      list: [],
      detail: {}
    };
  },

  mounted() {
    this.getList();
  },

  methods: {
    getList() {
      this.loading = true;

      fetchList()
        .then(res => {
          this.loading = false;
          if (Array.isArray(res)) {
            this.list = res;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },

    change(text) {
      const that = this;
      this.$confirm({
        title: "是否继续？",
        okText: "是",
        cancelText: "否",
        onOk() {
          const hide = that.$message.loading("操作中...", 0);
          control({
            id: text.id
          })
            .then(() => {
              that.getList();
            })
            .finally(() => {
              hide();
            });
        }
      });
    },

    seeDetail(text) {
      this.detail = text;
    }
  }
};
</script>

<style>
</style>